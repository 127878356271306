export default{
    // isLoading: (state) => state.loading,
    getLoginWithGoogleData: (state) => state.setLoginWithGoogleData,
    getUserProfile: (state) => state.userProfile,
    getToken : (state)=> state.token,
    getCurrentUserId: (state) => state.currentUserId,
    getCurrentUserName: (state) => state.currentUserName,
    getCurrentUserEmail: (state) => state.currentUserEmail,
    getIsLoggIn: (state) => state.isLoggedIn,
    getIsNewUser: (state) => state.isNewUser,
    // login with google ends
    isEmailRegistered: (state) => state.isEmailRegistered, // Get the result of email check (true, false, or null),
    getEmailCheckError: (state) => state.emailCheckError, // Get email check-related error message,

    getResponseMessage: (state) => state.responseMessage,
    getSignUpResponse: (state) => state.signUpResponse,
    getLoginError: (state) => state.loginError,
    getUserId:(state) => state.userId,
    getSignup:(state) => state.signUp,
    getVerificationResponseMessage:(state) => state.userVerification,  // gets the user verification and check if it matches
    getResponseMessage:(state) => state.responseMessage,
    getForgetPassword:(state) => state.forgetPassword,
    forgetPasswordResponse: (state) => state.forgetPasswordResponse,
    forgetPasswordErrors: (state) => state.forgetPasswordErrors,
    forgetPasswordError: (state) => state.forgetPasswordError,
    getResetPassword: (state) => state.resetPassword,
    getUserDetails: (state) => state.userDetails,
    getDataError:(state) =>state.dataError,
    getDeleteFundingRequest:(state) =>state.fundingRequests,
    completeFormData: state => {
      return {
        ...state.firstSectionFormData,
        ...state.secondSectionFormData,
        // ... other sections
      };
    }
}
<template>
    <!-- <header class="fixed top-0 left-0 z-30 bg-white w-full shadow-md py-4 h-20"> -->
    <header class="fixed top-0 left-0 z-30 bg-white w-full shadow py-4 h-20 px-6 md:px-32 ">
        <div class="max-w-[1500px] mx-auto">
            <nav class="flex w-full justify-between items-center mx-auto "><!--lg:px-32-->
                <div class="lg:block">
                    <a href="/">
                        <img src="../../assets/logo.png" alt="koppoh logo" />
                    </a>
                </div>

                <!-- Mobile Navigation -->
                <div class="lg:hidden flex items-center justify-end relative">
                    <button @click="showMenu = !showMenu" type="button"
                        class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400">
                        <svg v-if="!showMenu" viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                            <path fill-rule="evenodd"
                                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
                            </path>
                        </svg>
                        <svg v-else viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                            <path fill-rule="evenodd" d="M18 4.41L19.41 6 12 13.41 4.59 6 6 4.59 12 10.18 18 4.41z">
                            </path>
                        </svg>
                    </button>
                    <div v-if="showMenu"
                        class="fixed -top-6 -left-0 w-screen h-screen relative bg-indigo-800 z-30 flex flex-col items-center justify-start">
                        <button @click="showMenu = false" type="button"
                            class="absolute top-4 right-12 text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400">
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_352_4380)">
                                    <path
                                        d="M38 12.82L35.18 10L24 21.18L12.82 10L10 12.82L21.18 24L10 35.18L12.82 38L24 26.82L35.18 38L38 35.18L26.82 24L38 12.82Z"
                                        fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_352_4380">
                                        <rect width="48" height="48" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                        <ul class="flex flex-col mt-28 items-center space-y-2">
                            <li>
                                <router-link to="/about-us"
                                    class="w-[280px] h-14 px-2 py-3 border-b justify-start items-center gap-6 inline-flex">
                                    <div class="text-white text-base font-semibold leading-loose">About us</div>
                                </router-link>
                            </li>
                           <!-- <li>
                                <router-link to="/our-solutions"
                                    class="w-[280px] h-14 px-2 py-3 border-b justify-start items-center gap-6 inline-flex">
                                    <div class="text-white text-base font-semibold leading-loose">Our solutions</div>
                                </router-link>
                            </li> -->
                            <li>
                                <router-link to="/investors-home"
                                    class="w-[280px] h-14 px-2 py-3 border-b justify-start items-center gap-6 inline-flex">
                                    <div class="text-white text-base font-semibold leading-loose">Invest</div>
                                </router-link>
                            </li>
                            <!--<li>
                                <router-link to="/faqs"
                                    class="w-[280px] py-3 border-b justify-start items-center inline-flex">
                                    <AccordionItem title="Help">
                                        <router-link to="/faq"
                                            class="w-[280px] h-14 px-2 py-3 border-b justify-start items-center gap-6 inline-flex">
                                            <div class="text-white text-base font-semibold leading-loose">Faq</div>
                                        </router-link>
                                        <router-link to="/contact-us"
                                            class="w-[280px] h-14 px-2 py-3 justify-start items-center gap-6 inline-flex">
                                            <div class="text-white text-base font-semibold leading-loose">Contact us
                                            </div>
                                        </router-link>
                                    </AccordionItem>
                                </router-link>

                            </li>-->
                            <li>
                                <a href="https://blog.koppoh.ng/" target="_blank"
                                    class="w-[280px] h-14 px-2 py-3 border-b justify-start items-center gap-6 inline-flex">
                                    <div class="text-white text-base font-semibold leading-loose">Blog</div>
                                </a>
                            </li>
                            <li v-if="!isLoggedIn()">
                                <router-link to="/login/identifier"
                                    class="w-[280px] h-14 px-2 py-3 border-b justify-start items-center gap-6 inline-flex">
                                    <div class="text-white text-base font-semibold leading-loose">Login</div>
                                </router-link>
                            </li>
                            <li v-if="!isLoggedIn()">
                                <button @click="signup()"
                                    class="w-[280px] h-14 p-3 bg-white rounded-lg border border-indigo-800 justify-start items-center gap-2 inline-flex">
                                    <div class="text-indigo-800 text-base font-semibold leading-loose">
                                        Apply now
                                    </div>
                                </button>
                            </li>
                            <li v-if="isLoggedIn()">
                                <router-link to="/overview">
                                    <button
                                        class="w-[280px] h-14 p-3 bg-white rounded-lg border border-indigo-800 justify-start items-center gap-2 inline-flex">
                                        <div class="text-indigo-800 text-base font-semibold leading-loose">
                                            Dashboard
                                        </div>
                                    </button>
                                </router-link>
                            </li>
                        </ul>
                    </div>

                </div>

                <!-- Desktop Navigation -->
                <div class="hidden lg:flex items-center text-grey-color-500">
                    <router-link to="/about-us" class="text-base p-2 mr-6 leading-8 hover:text-primary-blue-500"
                        :class="{ 'active-link': activeLink === '/about-us' }" @click="setActiveLink('/about-us')">
                        About us
                    </router-link>
                    <!--<router-link to="/our-solutions" class="text-base p-2 mr-6 leading-8 hover:text-primary-blue-500"
                        :class="{ 'active-link': activeLink === '/our-solutions' }"
                        @click="setActiveLink('/our-solutions')">
                        Our Solutions
                    </router-link>-->
                    <router-link to="/investors-home" class="text-base p-2 mr-6 leading-8 hover:text-primary-blue-500"
                        :class="{ 'active-link': activeLink === '/investors-home' }"
                        @click="setActiveLink('/investors-home')">
                        Invest
                    </router-link>

                    <!-- <Menu as="div" class="relative ">
                        <div>
                            <MenuButton class="text-base p-2 mr-6 leading-8 hover:text-primary-blue-500"
                                :class="{ 'active-link': activeLink === '/help' }" @click="setActiveLink('/help')">
                                Help
                            </MenuButton>
                        </div>

                       <transition enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                            <MenuItems
                                class="absolute py-4 right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div class="py-1">
                                    <MenuItem v-slot="{ active }">
                                    <a href="/faqs"
                                        :class="[active ? 'bg-primary-blue-50 text-grey-color-500' : 'text-grey-color-500', 'block p-3 pl-5 text-base']">
                                        FAQ
                                    </a>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                    <a href="/contact-us"
                                        :class="[active ? 'bg-primary-blue-50 text-grey-color-500' : 'text-grey-color-500', 'block p-3 pl-5 text-base']">
                                        Contact us
                                    </a>
                                    </MenuItem>

                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>-->
                    <a href="https://blog.koppoh.ng/" target="_blank"
                        class="text-base p-2 mr-6 leading-8 hover:text-primary-blue-500"
                        :class="{ 'active-link': activeLink === '/blog' }" @click="setActiveLink('/blog')">
                        Blog
                    </a>
                    <router-link to="/login/identifier"
                        class="text-base p-2 mr-6 leading-8 font-semibold text-primary-blue-500 hover:text-primary-blue-500"
                        :class="{ 'active-link': activeLink === '/login/identifier' }"
                        @click="setActiveLink('/login/identifier')">
                        Login
                    </router-link>
                    <router-link to="/sign-up"
                        class="text-base p-3 px-10 leading-8 text-primary-blue-500 rounded-lg border border-primary-blue-500 hover:text-primary-blue-900 hover:border-primary-blue-900"
                        :class="{ 'active-link': activeLink === '/sign-up' }" @click="setActiveLink('/sign-up')">
                        <!-- <span class="font-semibold text-base ">Apply now</span> -->
                        <span class="font-semibold text-base ">Apply Now</span>
                    </router-link>
                    <!--<router-link v-if="isLoggedIn()" to="/overview"
                        class="text-base p-3 px-10 leading-8 text-primary-blue-500 rounded-lg border border-primary-blue-500 hover:text-primary-blue-900 hover:border-primary-blue-900">
                        <span class="font-semibold text-base ">Dashboard</span>
                    </router-link>-->
                </div>
            </nav>
        </div>
    </header>
</template>

<script>
import AccordionItem from '@/components/reusable/NavigationAccordion.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { isAuthenticated } from '@/components/services/auth';
export default {
    name: "Navigation",
    components: {
        AccordionItem,
        Menu, MenuButton, MenuItem, MenuItems
    },
    data() {
        return {
            showMenu: false,
            activeLink: null,
        }
    },
    mounted() {
        this.setActiveLink(this.$route.path)
        // console.log("Reporting lie from header: Authenticated? ", isAuthenticated())
    },
    methods: {
        setActiveLink(routeName) {
            this.activeLink = routeName;
            // console.log("The link is: ", this.activeLink)
        },
        signup() {
            this.$router.push("/sign-up");
        },
        login() {
            this.$router.push("/login/identifier");
        },
        isLoggedIn() {
            return isAuthenticated()
        }
    },
}
</script>

<style scoped>
.shadow {
    box-shadow: 0px 4px 30px 0px rgba(31, 31, 31, 0.10);
}

* {
    font-family: 'Open Sans';
}

.menuback {
    font-family: 'Open Sans';
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
    color: #2D2D2D;
    cursor: pointer;
    transition: 0.3s;
    margin-right: 30px;
    /* transition: color .2s ease-in-out; */
    transition: color .2s ease-in-out, border-bottom .3s ease-in-out, margin-bottom .3s ease-in-out;
    display: inline-block;
    position: relative;
    gap: 24px;
}

.menuback:active {
    border-bottom: 5px solid #2A2F9A;
    margin-bottom: -2px;
}

.active-link {
    border-bottom: 2px solid #2A2F9A;
    color: #2A2F9A;
    margin-bottom: -5px;
    font-weight: 700;
}

.btn-get-started {
    border-radius: 8px;
    background: white;
    color: #2A2F9A;
    border-color: #2A2F9A;
    width: 108px;
    height: 56px;
}

.btn-get-started:hover {
    background-color: #2A2F9A;
    color: white;
    border-color: #2A2F9A;
}

.btn-get-started:active {
    background-color: #2A2F9A;
    color: white;
    border-color: #2A2F9A;
}



@media (min-width: 640px) {}
</style>
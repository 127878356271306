import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
    namespaced: true,
    state(){
        return{
            // isLoading: false,
            loginErrors: [], // To store login errors
            setLoginWithGoogleData: {},  // Data to be passed when logging in with Google.
            userProfile: {},             // User profile data (from API)
            currentUserId: "",           // Current logged-in user's ID
            currentUserName: {firstName:"", lastName:""},   // Current logged-in user's name
            currentUserEmail:"",
            token : null ,                  // JWT Token for authentication
            isLoggedIn: false,                // Is the user currently logged in?
            newUser:  {},                       // Newly created user info
            isNewUser: {isSaved:false},         // Flag indicating if a new user has been added
            
            isEmailRegistered: null, // Indicates if the email is registered (null if not checked)
            emailCheckError: null, // Holds email check-related error messages
            
            responseMessage: "", // To store the response message
            signUpResponse: "",     // To store the response for sign up
            loginError: null, // To store the login error message
            signUp: null,
            userVerification: "",
            
            forgetPassword: "",
            forgetPasswordResponse: null,
            forgetPasswordErrors: null,
            forgetPasswordError: null,
            resetPassword: null,

            userId: sessionStorage.getItem('uid'),
            loggedFirstName: sessionStorage.getItem('loggedfirstName'),
            loggedlastName: sessionStorage.getItem('loggedlastName'),
            userDetails:{},
            dataError:null,

            fundingRequests: [],
        }
    },
    mutations: mutations,
    actions: actions,
    getters: getters,
}
<!-- AccordionItem.vue -->
<template>
    <div class="">
        <div class="accordion-header font-semibold text-base text-white" @click="toggleAccordion">
            <span class="w-5/6">{{ title }}</span>
            <Icon v-if="isOpen" class="w-6 h-6" icon="ic:round-keyboard-arrow-up" />
            <Icon v-else class="w-6 h-6" icon="ic:round-keyboard-arrow-down" />
        </div>
        <div v-if="isOpen" class="accordion-content">
            <slot></slot>
        </div>
    </div>
</template>
  
<script>
import { Icon } from '@iconify/vue';
export default {
    components:{
        Icon,
    },
    data() {
        return {
            isOpen: false,
        };
    },
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    methods: {
        toggleAccordion() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>
  
<style scoped>
/* Add your Tailwind CSS styling here */
.accordion-header {
    cursor: pointer;
    display: flex;
    justify-content: start;
    padding: 1rem;
    padding-left: .5rem;
    /**background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;*/
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.accordion-content {
    padding: 1rem;
}
</style>
  
export default {
    // LOADING(state, payload) {
    //     state.isLoading = payload;
    //     setTimeout(() => {
    //     // After 35 seconds, set loading to false
    //     state.isLoading = false;
    //     }, 35000); // 35 seconds in milliseconds
    //     state.forgetPasswordErrors = null;
    //     state.forgetPasswordError = null;
    // },
    
    // SET_USERDATA_FROM_GOOGLE(state, userDataFromGoogle){
    //     // console.log("SET USER DATA FROM GOOGLE: ",userDataFromGoogle);
        
    //     let newUser={};
    //     if (userDataFromGoogle != undefined && "displayName" in userDataFromGoogle) {
    //         newUser=userDataFromGoogle;
            
    //         // If the email is not provided by Google OAuth2, we will use an email from our own server database.
    //         // Set the email as lowercase for consistency
    //         newUser["email"]=newUser["email"].toLowerCase();
    //     } else{
    //         SET_LOGIN_ERROR('No user data from Google',);
    //         return;
    //     }

    //     // Checking if the user is already registered and log him/her in or redirect them to the registration page
    //     // Checking if this is a returning user or a new one
    //     if ("uid" in newUser) {
    //         // User is already logged in
    //         state.currentUserId=newUser["uid"];
    //         state.currentUserName=newUser["name"];
    //         state.currentUserEmail=newUser["email"];
    //         state.isLoggedIn=true;
    //     }else{
    //         // This is a new user
    //         state.newUser=newUser;
    //         state.isNewUser=true;
    //     }
    // },

    SET_USERDATA_FROM_GOOGLE(state, userDataFromGoogle) {
        // console.log("SET USER DATA FROM GOOGLE: ", userDataFromGoogle);
    
        if (userDataFromGoogle && userDataFromGoogle.displayName) {
            let newUser = { ...userDataFromGoogle }; // Copy the userDataFromGoogle object
    
            // If the email is not provided by Google OAuth2, use an email from your server database
            // Set the email as lowercase for consistency
            newUser.email = newUser.email ? newUser.email.toLowerCase() : 'example@example.com';
    
            // Checking if the user is already registered and log them in or redirect to the registration page
            if (newUser.uid) {
                // User is already logged in
                state.currentUserId = newUser.uid;
    
                // Split display name into first and last names
                const [firstName, lastName] = newUser.displayName.split(' ');
                state.currentUserName = { firstName, lastName };
    
                state.currentUserEmail = newUser.email;
                state.isLoggedIn = true;
            } else {
                // This is a new user
                state.newUser = newUser;
                state.isNewUser = true;
            }
        } else {
            // Handle the case where userDataFromGoogle is not as expected
            // SET_LOGIN_ERROR('No user data from Google');
            // console.log('No user data from Google');
        }
    },
    
    
    SET_LOGIN_ERROR(state, error) {
        state.loginError = error;
    },
    SET_RESPONSE_MESSAGE(state, message) {
        state.responseMessage = message;
    }, 
    SET_SIGNUP_RESPONSE(state, message) {
        state.signUpResponse = message;
    }, 


    CLEAR_LOGIN_ERROR(state) {
        state.loginError = null;
    },

    SET_LOGIN_ERROR(state, error) {
        state.loginErrors.push(error);
    },

    CLEAR_LOGIN_ERRORS(state) {
        state.loginErrors = [];
    },
    SET_EMAIL_REGISTERED: (state, payload) => {
        state.userEmail = payload;
    },
    SET_EMAIL_REGISTERED_ERROR: (state, error) => {
        state.isEmailRegistered = false; // Set isEmailRegistered to false to indicate an error
        state.emailCheckError = error;
    },
    SET_SIGNUP(state, payload){
        state.signUp = payload;
    },
    SET_VERIFICATION_RESPONSE_MESSAGE(state, message){
        state.userVerification = message;
    },
    SET_FORGETPASSWORD(state, payload){
        state.forgetPassword = payload;
    },
    // SET_FORGETPASSWORD(state, response) {
    //     state.forgetPasswordResponse = response;
    // },
    SET_FORGETPASSWORD_ERRORS(state, errors) {
        state.forgetPasswordErrors = errors;
    },
    SET_FORGETPASSWORD_ERROR(state, error) {
        state.forgetPasswordError = error;
    },
    SET_RESETPASSWORD(state, payload) {
        state.resetPassword = error;
    },
    SET_USER_DETAILS(state, payload){
        state.userDetails = payload;
    },
    SET_DATA_ERROR(state, error){
        state.dataError = error;
    },
    updateFormSection(state, { section, data }) {
        state[`${section}FormData`] = data;
    },
    DELETE_FUNDING_REQUEST(state, fundingRequestId) {
        state.fundingRequests = state.fundingRequests.filter(request => request.id !== fundingRequestId);
    },

}
<template>
    <div>
        <div v-if="isSuccess == true" id="success-message-container" class="fixed top-10 right-10 z-50">
            <div class="max-w-xs bg-green-100 text-green-600 border-0 rounded-md  shadow-lg dark:bg-gray-800 dark:border-gray-700"
                role="alert">
                <div class="flex p-4">
                    <div class="flex-shrink-0">
                        <svg class="h-4 w-4 text-green-600 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            fill="currentColor" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <p class="text-sm text-green-600 dark:text-gray-400">
                            {{ message }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-else id="failure-message-container" class="fixed top-10 right-10 z-50">
            <div class="max-w-xs bg-red-100 text-red-600 border-0 rounded-md  shadow-lg dark:bg-gray-800 dark:border-gray-700"
                role="alert">
                <div class="flex p-4">
                    <div class="flex-shrink-0">
                        <svg class="h-4 w-4 text-red-600 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            fill="currentColor" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <p class="text-sm text-red-600 dark:text-gray-400">
                            {{ message }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isSuccess:{
            type:Boolean,
            default:false
        },
        message:{
            type:String,
            default:"Toast message here"
        },
    },
    created() {

    },
    computed: {

    },
    mounted() {


    },
    methods: {

    }
}
</script>
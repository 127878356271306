<template>
    <footer class="w-full h-full bg-primary-blue-500 bg-no-repeat bg-cover py-20 px-6 md:py-28 md:px-32">
        <div class="max-w-[1500px] mx-auto">
            <div class="grid grid-cols-12 md:gap-10 pb-10">
                <div class="col-span-12 md:col-span-5 text-white">
                    <p class="text-[32px] font-bold leading-[48px] mt-2">
                        Subscribe to our Newsletters
                    </p>
                    <p class="mt-2 text-xl leading-8">
                        Get business tips, industry insights, and updates on new
                        features delivered straight to your inbox!
                    </p>
                </div>
                <div class="col-span-12 md:col-span-7 mt-10 md:mt-0">
                    <div class="p-10 bg-primary-blue-600 rounded-lg">
                        <form @submit.prevent="submitContactForm" class="">
                            <div class=" ">
                                <label for="first-name-input" class="block text-sm mb-1 leading-6 text-white">
                                    Enter your email address
                                </label>
                                <div class="grid grid-cols-12 mt-1 gap-4">
                                    <div class="col-span-12 md:col-span-9 md:mr-4 ">
                                        <input type="email" id="email-input" v-model.trim="userEmail"
                                            class="text-base font-semibold py-3 px-4 block bg-transparent text-white w-full border border-grey-color-100 rounded-md focus:border-grey-color-50 focus:ring-grey-color-100 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                            placeholder="" required>
                                    </div>
                                    <div class="col-span-12 md:col-span-3">
                                        <button v-if="subscribedBtn"
                                            class="p-3 rounded-lg w-full bg-white text-primary-blue-500">
                                            <p class="font-semibold text-base">Subscribed!</p>
                                        </button>
                                        <button v-else @click="subscribeToNewsLetter"
                                            class="p-3 rounded-lg w-full bg-white text-primary-blue-500">
                                            <span v-if="loadingSub"
                                                class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-primary-blue-500  rounded-full"></span>
                                            <p v-if="!loadingSub" class="font-semibold text-base">Subscribe now</p>
                                        </button>


                                    </div>
                                </div>

                                <div v-if="emailError" id="email-error"
                                    class="flex text-sm rounded-lg justify-start items-center my-4 gap-x-2 text-red-500">
                                    <Icon class="w-4 h-4" icon="ic:outline-info" />
                                    <p>{{ emailError }}</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <hr>
            <div class="grid grid-cols-12 mt-20 text-white pb-10">
                <div class="col-span-12 md:col-span-8">
                    <div>
                        <img src="../../assets/footerLogo.png" alt="" srcset="">
                        <p class="text-base leading-8 w-3/5 mt-6">
                            Koppoh helps you to prepare fundraising documentation
                            that meets prospective investors' needs.
                        </p>
                    </div>
                    <div class="mt-10">
                        <p class="text-xl font-semibold">
                            Contact us
                        </p>
                        <div class="py-4">
                            <p class="text-base">
                                <a href="mailto:hello@koppoh.com">
                                    hello@koppoh.com
                                </a>
                            </p>
                            <p class="text-base mt-2">+234 (0) 706 063 9202</p>
                            <p class="text-base mt-2">10D Lugard Avenue, Ikoyi, Lagos, Nigeria</p>
                        </div>
                        <div class="flex items-center">
                            <a href="https://www.facebook.com/koppohng" target="_blank" class="mr-6">
                                <img src="../../assets/Facebook.svg" alt="" srcset="">
                            </a>
                            <a href="https://www.linkedin.com/company/koppoh/" target="_blank" class="mr-6">
                                <img src="../../assets/Linkedin.svg" alt="" srcset="">
                            </a>
                            <a href="https://twitter.com/koppohng" target="_blank" class="mr-6">
                                <img src="../../assets/x.svg" alt="" srcset="">
                            </a>
                            <a href="https://www.instagram.com/koppoh.ng/" target="_blank" class="mr-6">
                                <img src="../../assets/instagram.svg" alt="" srcset="">
                            </a>
                        </div>

                    </div>

                </div>
                <div class="col-span-12 md:col-span-4 mt-6 md:mt-0">
                    <div class="grid grid-cols-12">
                        <div class="col-span-12 mt-10 md:mt-0 md:col-span-6">
                            <p class="text-xl font-semibold">
                                Company
                            </p>
                            <div class="mt-10 text-base">
                                <router-link to="/about-us" class="mt-6  block">
                                    <span>About us</span>
                                </router-link>
                                <router-link to="/faqs" class="mt-6 block">
                                    <span>FAQ</span>
                                </router-link>
                                <a href="https://blog.koppoh.ng/" target="_blank" class="mt-6 block">
                                    <span>Blog/news</span>
                                </a>
                            </div>
                        </div>
                        <div class="col-span-6 mt-10 md:mt-0 md:col-span-6">
                            <p class="text-xl font-semibold">
                                Solution
                            </p>
                            <div class="mt-10 text-base">
                                <router-link to="" class="mt-6  block">
                                    <span>Our Solutions</span>
                                </router-link>
                                <router-link to="/apply-for-fund" class="mt-6 block">   
                                    <span>Apply for Fund</span>
                                </router-link>
                                <router-link to="/investors-home" class="mt-6 block">
                                    <span>Become an Investor</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="pb-10">
                <h6 class="text-white text-base mt-10">
                    Powered by <span class="text-lg underline font-bold">
                        <a href="https://alphaafrican.com/" target="_blank"> Alpha African Advisory </a>
                    </span>
                </h6>
            </div>
        </div>

    </footer>
    <Toast class="z-100" v-if="toastFired" :isSuccess="toastIsSuccess" :message="toastMessage" />
</template>
<script>
import { Icon } from '@iconify/vue';
import { mapGetters, mapActions } from "vuex";
import Toast from "@/components/reusable/Toast.vue"
export default {
    name: "AppHeader",
    data() {
        return {
            userEmail: "",
            emailError: "",
            emailSuccess: "",
            loadingSub: false,
            subscribedBtn: false,
            toastFired: false,
            toastIsSuccess: false,
            toastMessage: '',
        };
    },
    components: {
        Icon,
        Toast,
    },
    computed: {
        ...mapGetters('authentication', ['getUserId', 'getResponseMessage']),
    },
    created() {


    },
    mounted() {


    },
    methods: {
        ...mapActions('authentication', ['addEmailToNewsLetter']),
        async subscribeToNewsLetter() {
            this.loadingSub = true;
            this.emailError = "";
            let validationResults = [];
            let emailInput = document.getElementById('email-input');
            if (!this.userEmail || this.userEmail.trim() === '') {
                this.emailError = "This is required."
                emailInput.style.color = 'red';
                emailInput.style.borderColor = 'red'
                validationResults.push(false)
            }
            let allowedPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/
            let emailTest = allowedPattern.test(this.userEmail);
            if (!emailTest) {
                this.emailError = "Enter a valid email."
                emailInput.style.color = 'red';
                emailInput.style.borderColor = 'red'
                validationResults.push(false)
            }
            // console.log("Validation:", validationResults)
            if (!validationResults.includes(false)) {
                let payload = {
                    email: this.userEmail
                }

                let addedEmailToNewsLetter = await this.addEmailToNewsLetter(payload)
                if (addedEmailToNewsLetter.status == 200) {
                    this.toastFired = true;
                    this.toastIsSuccess = true;
                    this.toastMessage = "Your email has been added successfully"
                    //automatically close toast
                    setTimeout(() => {
                        this.toastFired = false
                    }, 3000)
                } else {
                    this.toastFired = true;
                    this.toastIsSuccess = false;
                    this.toastMessage = "Something went wrong. Please try again"
                    //console.log("The result: ", addedEmailToNewsLetter)
                    //automatically close toast
                    setTimeout(() => {
                        this.toastFired = false
                    }, 3000)
                }

            }
            this.loadingSub = false
        }
    },
}
</script>
import { createApp } from 'vue'
import firebase from './firebase'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import('preline')
import VueScrollTo from 'vue-scrollto';
import App from './App.vue'
import router from './router'
import store from './store/store'
import { getAuth } from 'firebase/auth';
import "preline/preline";
import 'flowbite'; 
import { initFlowbite } from 'flowbite'
// import VueMeta from 'vue-meta'
// import { createMetaManager } from 'vue-meta'
// import { plugin as vueMetaPlugin } from "vue-meta";

import gAuthPlugins from 'vue3-google-oauth2'
import vue3GoogleLogin from 'vue3-google-login'
import './main.css'
// Global component.
import Loading from './components/reusable/Loading.vue';
import Navigation from './components/navigation/Navigation.vue'
import Navigation2 from './components/navigation/Navigation2.vue'
import Footer from './components/navigation/Footer.vue'
import DatePicker from 'vue-datepicker-next';
import LoginWithGoogleBtn from '@/components/authentication/LoginWithGoogleBtn'
import 'vue-datepicker-next/index.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { isAuthenticated,setupAutoLogout, checkAutoLogout } from '../src/components/services/auth';

// Analytics
import mixpanel from 'mixpanel-browser';
import VueGtag from 'vue-gtag';
library.add(faEye, faEyeSlash)


let app = ""
const mixPanelToken = "cb95d55c1499c7b9f81144605c4a2f98";
const userId = sessionStorage.getItem('uid');
getAuth().onAuthStateChanged(user => {
  if (!app) {
    app = createApp(App);
    // const metaManager = createMetaManager()
    // Replace 'YOUR_MIXPANEL_TOKEN' with your actual Mixpanel project token
    mixpanel.init(mixPanelToken, { debug: true, track_pageview: true, persistence: 'localStorage' });
    // Set this to a unique identifier for the user performing the event.
    mixpanel.identify('USER_ID');
      mixpanel.track('Sign Up', {
        'Signup Type': 'Email',
        'User ID': userId,
      })

    app.component('Loading', Loading); // Register the component globally
    app.component('Navigation', Navigation); // Register the component globally
    app.component('Navigation2', Navigation2);
    app.component('Footer', Footer); // Register the component globally
    app.component('DatePicker', DatePicker);
    app.component('font-awesome-icon', FontAwesomeIcon);
    app.component('LoginWithGoogleBtn', LoginWithGoogleBtn);

    app.use(firebase)
    app.use(VueScrollTo)
    app.use(ElementPlus)
    app.use(VueGtag, {
      config: { id: "G-7YCHFFWRJ4" }
    })

    // app.use(vueMetaPlugin);
    // app.use(createMetaManager);

    app.use(store)
    app.use(router)
    app.config.globalProperties.$mixpanel = mixpanel; // Add Mixpanel to the global properties
    
    app.mount('#app')
  }
})
// Vue.config.productionTip = false;



<template>
    <!-- <header class="fixed top-0 left-0 z-30 bg-white w-full shadow-md py-4 h-20"> -->
    <header class="fixed top-0 left-0 z-30 bg-white w-full shadow py-4 h-20">
        <nav class="flex justify-between items-center mx-auto px-4 lg:px-32">
            <div class=" sm:block">
                <router-link to="/">
                    <img src="../../assets/logo.png" alt="koppoh logo"/>
                </router-link>
            </div>

             <!-- Mobile Navigation -->
            <div class="sm:hidden flex items-center justify-end relative">
                <button @click="showMenu = !showMenu" type="button" class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400">
                    <svg v-if="!showMenu" viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                        <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
                    </svg>
                    <svg v-else viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                        <path fill-rule="evenodd" d="M18 4.41L19.41 6 12 13.41 4.59 6 6 4.59 12 10.18 18 4.41z"></path>
                    </svg>
                </button>
                <div v-if="showMenu" class="fixed -top-6 -left-0 w-screen h-screen relative bg-indigo-800 z-30 flex flex-col items-center justify-start">
                    <button @click="showMenu = false" type="button" class="absolute top-4 right-12 text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_352_4380)"><path d="M38 12.82L35.18 10L24 21.18L12.82 10L10 12.82L21.18 24L10 35.18L12.82 38L24 26.82L35.18 38L38 35.18L26.82 24L38 12.82Z" fill="white"/></g>
                            <defs><clipPath id="clip0_352_4380"><rect width="48" height="48" fill="white"/></clipPath></defs>
                        </svg>
                    </button>
                    <ul class="flex flex-col mt-28 items-center space-y-2">
                        <li>
                            <router-link to="/" class="w-[280px] h-14 px-2 py-3 border-b border-slate-500 justify-start items-center gap-6 inline-flex">
                                <div class="text-white text-base font-semibold leading-loose">Home</div>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/about-us" class="w-[280px] h-14 px-2 py-3 border-b border-slate-500 justify-start items-center gap-6 inline-flex">
                                <div class="text-white text-base font-semibold leading-loose">About us</div>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/our-partners" class="w-[280px] h-14 px-2 py-3 border-b border-slate-500 justify-start items-center gap-6 inline-flex">
                                <div class="text-white text-base font-semibold leading-loose">Our partners</div>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/investor-universe" class="w-[280px] h-14 px-2 py-3 border-b border-slate-500 justify-start items-center gap-6 inline-flex">
                                <div class="text-white text-base font-semibold leading-loose">Investor universe</div>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/help" class="w-[280px] h-14 px-2 py-3 border-b border-slate-500 justify-start items-center gap-6 inline-flex">
                                <div class="text-white text-base font-semibold leading-loose">Help</div>
                            </router-link>
                            
                        </li>
                        <li>
                            <router-link to="/#" class="w-[280px] h-14 px-2 py-3 border-b border-slate-500 justify-start items-center gap-6 inline-flex">
                                <div class="text-white text-base font-semibold leading-loose">Blog</div>
                            </router-link>
                        </li>
                        <li>
                            <button @click="login" class="w-[280px] h-14 p-3 bg-white rounded-lg border border-indigo-800 justify-start items-center gap-2 inline-flex">
                                <!-- <div class="text-indigo-800 text-base font-semibold leading-loose">Apply for funds</div> -->
                                <div class="text-indigo-800 text-base font-semibold leading-loose">Connect with investors</div>
                            </button>
                        </li>
                    </ul>
                </div>

            </div>
            
            <!-- Desktop Navigation -->
            <div class="hidden sm:flex space-x-6 items-center">
                <span>
                    <router-link to="/#" class="menuback pl-4" >
                        Home
                    </router-link>
                </span>

                <span>
                    <router-link to="/about-us" class="menuback" >
                        About us
                    </router-link>
                </span>

                <span>
                    <router-link to="/our-partners" class="menuback" >
                        Our partners
                    </router-link>
                </span>
                <span>
                    <router-link to="/investor-universe" class="menuback" >
                        Investor universe
                    </router-link>
                </span>
                
                <span>
                    <router-link to="/help" class="menuback" :class="{ ' #2A2F9A': activeLink === 'help' }" @click="setActiveLink('help')">
                        Help
                    </router-link>
                </span>
                
                <span>
                    <router-link to="/#" class="menuback" :class="{ ' #2A2F9A': activeLink === 'about' }" @click="setActiveLink('about')">
                        Blog
                    </router-link>
                </span>
                <!-- <router-link to="/about" class="..." v-slot="{isActive}">
                    <div :class="{ 'active-link': isActive }">About us</div>
                </router-link> -->
                <!-- <button class="text-xs rounded-lg bg-white text-[#2A2F9A] border-2 border-[#2A2F9A] w-[108px] h-[48px] hover:bg-[#2a2f9a] hover:text-white">Apply for funds</button> -->
                <button @click="login" class="text-xs rounded-lg bg-white text-[#2A2F9A] border-2 border-[#2A2F9A] p-3 w-[206px] h-[56px] hover:bg-[#2a2f9a] hover:text-white">Connect with investors</button>
            </div>
        </nav>
    </header>
</template>

<script>
export default{
    name: "Navigation",
    data(){
        return{
            showMenu: false,
            activeLink: null,
        }
    },
    methods: {
        setActiveLink(routeName) {
            this.activeLink = routeName;
        },
        login(){
            this.$router.push("/login/identifier");
        }
    },
}
</script>

<style scoped>

.shadow{
    box-shadow: 0px 4px 30px 0px rgba(31, 31, 31, 0.10);
}
.menuback{
    font-family: 'Open Sans';
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
    color: #2D2D2D;
    cursor: pointer;
    transition: 0.3s;
    margin-right: 30px;
    /* transition: color .2s ease-in-out; */
    transition: color .2s ease-in-out, border-bottom .3s ease-in-out, margin-bottom .3s ease-in-out;
    display: inline-block;
    position: relative;
    gap: 24px;
}
.menuback:active {
    border-bottom: 5px solid #2A2F9A;
    margin-bottom: -2px; 
}
.active-link {
  border-bottom: 5px solid #2A2F9A;
  color: #2A2F9A;
  margin-bottom: -5px;
}
.btn-get-started{
    border-radius: 8px;
    background: white;
    color: #2A2F9A;
    border-color: #2A2F9A;
    width: 108px;
    height: 56px;
}
.btn-get-started:hover{
    background-color: #2A2F9A;
    color: white;
    border-color: #2A2F9A;
}
.btn-get-started:active{
    background-color: #2A2F9A;
    color: white;
    border-color: #2A2F9A;
}
@media (min-width: 640px){

}
</style>
<template>
  <div class="loader" v-if="isLoading">
    <img src="../../assets/logo.png" alt="Logo" class="logo">
  </div>
</template>
  
<script>
  export default {
  name: "Loading",
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>
  
<style scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure loader is on top */
}

.logo {
  max-width: 100px;
  animation: scaleLogo 1.5s ease-in-out infinite; /* Added scaling animation */
}
@keyframes scaleLogo {
  0%, 100% {
    transform: scale(1); /* Original size */
  }
  50% {
    transform: scale(1.2); /* Increased size */
  }
}
</style>
  
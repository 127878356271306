<template>
  <router-view />
</template>


<script>
export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'koppoh',
    // all titles will be injected into this template
    titleTemplate: '%s | We help businesses prosper'
  },
  data() {
    return {
      transitionName: 'fade',
    }
  },
  watch: {
    '$route'(to, from) {
      // Set transition based on route or any other condition
      this.transitionName = 'custom-transition';
    },
  },
  mounted() {
    
  }
}
</script>

<style>
#app {
  font-family: 'Maven Pro', sans-serif;
  /**font-family: 'Open Sans', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}

.circle-progress__svg {
  overflow: visible;
}

/* Custom CSS animation for fade transition */
.fade-enter-active,
.fade-leave-active {
  animation: fadeIn 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>

import { createStore } from 'vuex'
import authentication from './modules/authentication/';

export default createStore({
  namespaced: true, // Add the namespaced property and set it to true
  modules: {
    authentication,
  },


  state: {
    isLoading: false, // Initial loading state
    userData:{},
    isAuthenticated: false,
    fundingType:'',
  },
  getters: {
    isLoading: state => state.isLoading,
    getUserData: state => state.userData,
    isAuthenticated: (state) => state.isAuthenticated,
    getFundingType:(state) => state.fundingType,
  },
  mutations: {
    setLoading(state, value) {
      state.isLoading = value;
      setTimeout(() => {
        // After 35 seconds, set laoding to false
        state.isLoading = false;
      }, 35000);
    },
    setAuth(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setFundingType(state, fundingType){
      state.fundingType = fundingType;
    },
  },
  actions: {
    showLoader({ commit }) {
      commit('setLoading', true);
    },
    hideLoader({ commit }) {
      commit('setLoading', false);
    },
    updateAuth({ commit }, isAuthenticated) {
      commit('setAuth', isAuthenticated);
    },
    updateFundingType({commit}, fundingType){
      commit('setFundingType', fundingType)
    },
  },
  
})
